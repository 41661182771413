<template>
  <div class="myBids">
    <div class="tabs_box">
      <Tabs size="big" :showBorder="true" :tabData="bidsBar" @handleClick="changeHandle" />
    </div>
    <div class="page_content">
      <div class="status_tabs">
        <Tabs :tabData="statusList" :gapSize="60" @handleClick="changeStatus" />
      </div>
      <div class="list_box">
        <BidsList :bidsType="curBidsType" :lists="list_data.lists" :numberRow="1" v-if="list_data.lists.length"></BidsList>
        <el-empty :image-size="200" v-else></el-empty>
      </div>
      <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
    </div>
  </div>
</template>
  
<script>
import Tabs from '@/components/Tabs.vue'
import BidsList from '../../../components/Bids/BidsList.vue'
import mixin from '../../../mixin'
export default {
  mixins: [mixin],
  components: {
    Tabs,
    BidsList
  },
  data () {
    return {
      curBidsType: 'invitation',
      curStatus: 'accept',
      bidsBar: [
        {
          id: 1,
          text: '发布招标',
          value: 'invitation',
          active: true
        },
        {
          id: 2,
          text: '发布中标',
          value: 'win',
          active: false
        },
      ],
      statusList: [
        {
          id: 1,
          text: '已通过',
          value: 'accept',
          active: true
        },
        {
          id: 2,
          text: '待审核',
          value: 'wait',
          active: false
        },
        {
          id: 2,
          text: '已驳回',
          value: 'refused',
          active: false
        },
      ],
    }
  },
  created () {
    this.getData();
  },
  methods: {
    changeHandle (index) {
      this.bidsBar.forEach((item) => {
        item.active = false
      })
      this.bidsBar[index].active = true
      this.curBidsType = this.bidsBar[index].value;
      this.changeStatus(0);
      this.getData();
    },
    changeStatus (index) {
      this.statusList.forEach((item) => {
        item.active = false
      })
      this.statusList[index].active = true
      this.curStatus = this.statusList[index].value;
      this.getData();
    },
    getData () {
      if (this.curBidsType == 'invitation') {
        this.getList('getMyInvitation', this.page, { status: this.curStatus });
      } else {
        this.getList('getMyWin', this.page, { status: this.curStatus });
      }
    }
  },
  mounted () {
  },
  watch: {

  },
}
</script>
  
<style lang='scss' scoped>
.myBids {
  padding: 26px;
  .tabs_box {
    margin-bottom: 38px;
  }
  .page_content {
    .status_tabs {
      margin-bottom: 28px;
    }
    .list_box {
      min-height: 500px;
    }
  }
}
</style>